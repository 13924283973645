.about-page {
  font-family: "Ubuntu", serif;
  line-height: 1.6;
}

.main-image {
  position: relative;
  display: flex;
  color: #fff;
  font-family: "Ubuntu", serif;
  flex-wrap: wrap;
  padding: 30px;
  margin-top: 15%;
}

.video-container-abt {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin-left: 8%;
  overflow: hidden;
}

.video-abt {
  object-fit: cover;
  width: 100%;
  max-width: 700px;
  height: auto;
}

.about-overlay-text {
  position: relative;
  text-align: right;
  margin-left: 50%;
  right: 10%;
  margin-top: -12%;
  transform: translate(0%, -50%);
  color: rgb(14, 14, 14);
  border-radius: 10px;
  max-width: 90%;
  -webkit-transform: translate(0%, -50%);
  -moz-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -o-transform: translate(0%, -50%);
}

.about-overlay-text h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
}

 .highlight {
  display: inline-block;
  background: linear-gradient(to right, rgba(110, 108, 109, 0.700), rgba(110, 108, 109, 0.700), hsl(0, 0%, 1%) 20%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: shine 2s infinite linear;
  -webkit-animation: shine 2s infinite linear;
}

@keyframes shine {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1000px;
  }
}

.about-overlay-text h2 {
  text-align: right;
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.4;
  color: rgb(1, 1, 1);
  font-weight: bold;
}

.about-overlay-text p {
  text-align: right;
  font-size: 1.3rem;
  line-height: 1.4;
  color: rgb(1, 1, 1);
  margin-left: 10%;
  margin-top: 2rem;
}

.floating-shapes {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  pointer-events: none;
}

.button-container {
  margin-top: 1.5rem;
  text-align: right; 
}

.btn {
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  background-color: #e76f51; /* Primary button color */
  border: none;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-left: 1rem; /* Add spacing between buttons */
  width: 25%;
}

.btn:hover {
  background-color: #f28466; /* Hover color for buttons */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.get-started {
  background-color: #2a9d8f; /* Specific color for 'Get Started' */
}

.get-started:hover {
  background-color: #3db19f; /* Hover color for 'Get Started' */
}

.learn-more {
  background-color: #e76f51; /* Specific color for 'Learn More' */
}

.learn-more:hover {
  background-color: #f28466; /* Hover color for 'Learn More' */
}

.about-how-it-works {
  position: relative;
  padding: 70px 0;
  background: linear-gradient(135deg, #eef5ff 40%, #d1e8ff);
  overflow: hidden;
  text-align: center;
}

.about-how-it-works h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 60px;
  color: #333;
}

.how-it-works-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-size: cover;
  transform: rotate(180deg);
}

.how-it-works-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  margin-top: 100px;
  margin-bottom: 50px;
}

.how-it-works-item {
  width: 90%;
  max-width: 300px;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px 80px;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transform: rotate(-5deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.how-it-works-item:nth-child(even) {
  transform: rotate(5deg);
}

.how-it-works-item:hover {
  transform: rotate(0deg) scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.how-it-works-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #0077b6;
}

.how-it-works-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

.how-it-works-marker {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #0077b6;
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.2rem;
  font-weight: bold;
}

.about-empower-teams {
  padding: 70px 50px;
  text-align: center;
}

.about-empower-teams h2 {
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: bold;
}

.flowchart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px;
}

.flowchart-item {
  flex: 1 1 calc(50% - 20px);
  max-width: 25%;
  background: linear-gradient(135deg, #eef5ff 40%, #d1e8ff);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  height: 300px;
  perspective: 1000px;
}

.flowchart-item .flowchart-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
}

.flowchart-item:hover .flowchart-inner {
  transform: rotateY(180deg); 
}

.flowchart-front,
.flowchart-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; 
}

.flowchart-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flowchart-back {
  display: flex;
  flex-direction: column;
  justify-content: left;
  transform: rotateY(180deg);
}

.flowchart-front img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.about-footer {
  margin-top: 40px;
  padding: 10px 5%;
  border-top: 1px solid #ccc;
  color: #333;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 50px;
}

.logo-p img {
  height: 50px;
  margin-top: 20px;
}

.video-container-abt-gif{
  display: none;
}

/* Responsive Styles for Mobile View */
@media (max-width: 480px){
  .about-page {
    font-family: "Ubuntu", serif;
  }

  .main-image {
    height: auto;
    flex-direction: row;
    align-items: center;
    margin-top: 40%;
  }

  .video-container-abt{
    display: none;
  }

  .video-abt {
    display: none;
  }

  .video-container-abt-gif{
    display: inline;
  }

  .about-overlay-text {
    top: auto;
    transform: none;
    margin-left: 10%;
    text-align: center;
  }

  .about-overlay-text h1 {
    font-size: 1.9rem;
    text-align: center;
    width: 400px;
  }

  .about-overlay-text h2 {
    font-size: 1rem;
    text-align: center;
    width: 400px;
  }

  .about-overlay-text p {
    font-size: 1rem;
    text-align: justify;
    margin-left: 0;
    margin-right: 10%;
    width: 400px;
  }

  .button-container {
    margin-top: 1.5rem; /* Add spacing above buttons */
    display: flex;
    flex-direction: row; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    gap: 1rem; /* Add space between buttons */
    margin-left: 8%;
  }

  .btn {
    font-size: 0.5rem; /* Reduce button font size */
    padding: 0.6rem 0.8rem; /* Adjust padding */
    width: 90%; /* Make buttons wider */
    max-width: 100px;
    margin-bottom: 20%;
  }
  
  .about-how-it-works {
    padding: 65px;
    text-align: center;
    flex-direction: column;
  }

  .about-how-it-works h2 {
    font-size: 1.8rem;
  }

  .how-it-works-wave {
    position: absolute;
    height: 80px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

  .how-it-works-container {
    flex-direction: column;
    margin-top: 50px;
  }

  .how-it-works-item {
    flex: 0 1 90%;
    max-width: 90%;
    transform: rotate(0deg);
    transition: none;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
}

.how-it-works-item:nth-child(even) {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.how-it-works-item:hover {
  transform: rotate(0deg) scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

}

@media (max-width: 768px) {
  .about-page {
    font-family: "Ubuntu", serif;
  }

  .main-image {
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-top: 35%;
  }

  .video-container-abt-gif {
    display: inline;
    flex-direction: row;
    margin-left: 0;
    align-items: center;
    height: 50vh;
  }

  .video-abt-1 {
    margin-top: 20px;
    width: 80%;
    height: auto;
    margin-left: 15%;
    display: inline;
  }

  .video-container-abt {
    display: none;
  }

  .video-abt {
    display: none;
  }

  .about-overlay-text {
    top: auto;
    transform: none;
    text-align: center;
    margin-left: 5%;
  }

  .about-overlay-text h1 {
    margin-top: -10px;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    margin-left: 10%;
  }

  .about-overlay-text h2 {
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    margin-left: 10%;
  }

  .about-overlay-text p {
    font-size: 1rem;
    text-align: justify;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
  }
  
  .about-how-it-works {
    padding: 50px;
    text-align: center;
    flex-direction: column;
  }

  .about-how-it-works h2 {
    font-size: 1.8rem;
  }

  .how-it-works-wave {
    position: absolute;
    width: 90%;
    height: 60px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .how-it-works-container {
    flex-direction: column;
    gap: 10vh;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .how-it-works-item {
    width: 50%;
    padding: 25px;
    transform: rotate(0deg);
    transition: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .how-it-works-item:nth-child(even) {
    transform: rotate(0deg);
  }

  .how-it-works-item:hover {
    transform: rotate(0deg) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: 1024px) {
  .about-page {
    font-family: "Ubuntu", serif;
  }

  .main-image {
    height: auto;
    flex-direction: column;
    align-items: center;
    margin-top: 35%;
    margin-bottom: 5%;
  }

  .video-container-abt-gif {
    display: inline;
    flex-direction: row;
    margin-left: 0;
    align-items: center;
  }

  .video-abt-1 {
    margin-top: 20px;
    width: 80%;
    height: auto;
    margin-left: 15%;
  }

  .video-container-abt {
    display: none;
  }

  .video-abt {
    display: none;
  }

  .about-overlay-text {
    top: auto;
    transform: none;
    text-align: center;
    margin-left: 5%;
  }

  .about-overlay-text h1 {
    margin-top: -10px;
    font-size: 2rem;
    text-align: center;
    width: 100%;
    margin-left: 10%;
  }

  .about-overlay-text h2 {
    font-size: 1.2rem;
    text-align: center;
    width: 100%;
    margin-left: 10%;
  }

  .about-overlay-text p {
    font-size: 1rem;
    text-align: justify;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
  }

  .button-container {
    margin-top: 1.5rem; /* Add spacing above buttons */
    display: flex;
    flex-direction: row; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    gap: 1rem; /* Add space between buttons */
    margin-left: 10%;
  }

  .btn {
    font-size: 0.5rem; /* Reduce button font size */
    padding: 1rem 2rem; /* Adjust padding */
    width: 100%; /* Make buttons wider */
    max-width: 400px; /* Limit maximum button width */
  }

  .about-how-it-works {
    padding: 50px;
    text-align: center;
    flex-direction: column;
  }

  .about-how-it-works h2 {
    font-size: 1.8rem;
    margin-bottom: 10vh;
  }

  .how-it-works-wave {
    position: absolute;
    width: 90%;
    height: 60px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .how-it-works-container {
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .how-it-works-item {
    width: 50%;
    padding: 25px;
    transform: rotate(0deg);
    transition: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .how-it-works-item:nth-child(even) {
    transform: rotate(0deg);
  }

  .how-it-works-item:hover {
    transform: rotate(0deg) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }

  .about-empower-teams {
    text-align: center;
  }

  .about-empower-teams h2 {
    font-size: 2rem;
    margin-bottom: 10vh;
    text-align: center;
  }

  .flowchart-container {
    flex-direction: column;
  }

  .flowchart-item {
    flex: 1 1 auto;
    max-width: 80%;
    height: 50%;
    height: 350px;
    padding: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    margin-left: 10%;
    margin-bottom: 60px;
    perspective: 1000px;
  }

  .flowchart-front,
  .flowchart-back {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    backface-visibility: hidden  ;
    -webkit-backface-visibility: hidden;
}

.flowchart-item .flowchart-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
}
}

@media (max-width: 1336px) {
  .about-page {
    font-family: "Ubuntu", serif;
  }

  .main-image {
    margin-bottom: -15%;
    margin-top: 25%;
  }

  .about-overlay-text {
    margin-top: -15%;
  }

  .video-container-abt {
    display: flex;
    justify-content: left;
    flex-direction: row;
    margin-left: 8%;
    overflow: hidden;
  }
  
  .video-abt {
    object-fit: cover;
    width: 100%;
    height: auto
  }
}