/* General Section Styling */
.features-section {
  padding: 40px 20px;
  margin: 0 auto;
  height: 100vh;
  max-width: 2000px; /* Restrict the width for perfect alignment */
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  background: linear-gradient(135deg, #bde4f7, #cbcbf7, #f2d2f6);
  font-family: "Ubuntu", serif;
}

/* Heading Styling */
.features-section h1 {
  color: #080808;
  text-align: center;
  margin-bottom: 80px;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 3%;
}

/* Highlight Styling for the Heading */
.highlight1 {
  display: inline-block;
  background: linear-gradient(to right, rgba(110, 108, 109, 0.700), rgba(110, 108, 109, 0.700), hsl(0, 0%, 1%) 20%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: shine 2s infinite linear;
}

/* Feature Cards Container */
.feature-cards {
  display: flex;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid layout */
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 15vh;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Individual Feature Card Styling */
.feature-card {
  background: rgba(248, 247, 249, 0.706);
  border-radius: 16px;
  width: 20%;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  color: #050505;
  animation-duration: 1.5s; /* Duration of animation */
  animation-fill-mode: forwards; /* Ensures the animation stays at the final position */
  transition: transform 0.3s, box-shadow 0.3s;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
}

.feature-card:nth-child(1) {
  animation-name: slide-left-to-right;
}

.feature-card:nth-child(2) {
  animation-name: slide-left-to-right;
}

.feature-card:nth-child(3) {
  animation-name: slide-right-to-left;
}

.feature-card:nth-child(4) {
  animation-name: slide-right-to-left;
}

/* Hover Effect */
.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Feature Card Image */
.feature-card img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

/* Feature Card Heading */
.feature-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* Feature Card Description */
.feature-card p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  text-align: center;
}


/* Media Queries for Mobile Screens */
@media (max-width: 768px) {
  .features-section {
    padding: 20px 15px;
    height: auto;
    display: flex;
    justify-content: center; /* Center the feature cards horizontally */
    align-items: center; /* Center the feature cards vertically */
    flex-direction: column; 
  }

  .features-section h1 {
    font-size: 2rem;
    text-align: center;
    margin-top: 5%;
  }

  .feature-cards {
    display: flex;
    flex-direction: column; /* Stack cards in a single column */
    gap: 15vh;
    justify-content: center; /* Center cards in the feature cards container */
    align-items: center;
  }

  .feature-card {
    padding: 15px;
    width: 70%;
    justify-content: center; /* Center content inside the card */
    align-items: center;
  }
}

@media (max-width: 1336px) {
  .features-section {
    padding: 20px 15px;
    height: auto;
    display: flex;
    justify-content: center; /* Center the feature cards horizontally */
    align-items: center; /* Center the feature cards vertically */
    flex-direction: column; 
  }
}

@media (max-width: 1920px) {
  .features-section {
    padding: 20px 15px;
    height: auto;
    display: flex;
    justify-content: center; /* Center the feature cards horizontally */
    align-items: center; /* Center the feature cards vertically */
    flex-direction: column; 
  }
}