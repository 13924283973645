.main-image-section {
  position: relative;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  color: #fff;
  font-family: "Ubuntu", serif;
  flex-wrap: wrap;
  padding: 15px;
  margin-top: 5%;
}

.overlay-text {
  width: 70%;
  margin-left: 10%;
}

.overlay-text h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
}

.overlay-text p {
  font-size: 1.25rem;
  font-weight: bold;
  width: 50%;
}

.overlay-text h1 .highlight {
  display: inline-block;
  background: linear-gradient(to right, hsl(0, 9%, 9%) 0, hsl(0, 9%, 98%) 10%, hsl(0, 0%, 2%) 20%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: shine 2s infinite linear;
}

@keyframes shine {
  100% {
    background-position: 500%;
  }
}

.center p.highlight1 {
  color: #050505;
  font-weight: bold;
}

.highlight2 {
  color: #e76f51;
  font-weight: bold;
}

.text p {
  font-size: 1.3rem;
  line-height: 1.4;
  color: rgb(1, 1, 1);
  width: 45%;
  margin-left: 10%;
}

.main-nav-container {
  margin-top: 2%;
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  width: 30%;
  margin-left: 8.5%;
}

.main-nav-container.animate {
  opacity: 1;
  transform: translateY(0);
}

.gt-button-container {
  display: flex;
  gap: 3%;
  justify-content: center;
}

.get-started-btn, .learn-more-btn {
  font-size: 1rem;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease, transform 0.2s ease;
}

.get-started-btn {
  background-color: #2a9d8f;
  color: #fff;
}

.learn-more-btn {
  background-color: #e76f51;
  color: #fff;
}

.video-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: -36%;
  margin-right: 5%;
}

.video {
  width: 30%;
  height: 70vh;
  object-fit: cover;
}

.mobile-home-gif {
  display: none;
}

@media (max-width: 768px) {
  .main-image-section {
    position: relative;
    height: 100vh; /* Ensure it takes the entire screen */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: "Ubuntu", serif;
    padding: 0; /* Remove padding for full-screen effect */
    margin-top: 0; /* Remove margin */
    z-index: 1;
  }

  .overlay-text {
    margin-left: 4%;
    text-align: center;
  }

  .overlay-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;
    width: 120%;
  }

  .overlay-text p {
    font-size: 1rem;
    font-weight: normal;
    width: 100%;
    margin-left: 5%;
  }

  .overlay-text h1 .highlight {
    display: inline-block;
    background: linear-gradient(to right, hsl(0, 9%, 9%) 0, hsl(0, 9%, 98%) 10%, hsl(0, 0%, 2%) 20%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shine 2s infinite linear;
  }

  .center p.highlight1 {
    color: #050505;
    font-weight: bold;
  }

  .highlight2 {
    color: #e76f51;
    font-weight: bold;
  }

  .text p {
    font-size: 1rem;
    line-height: 1.4;
    color: rgb(1, 1, 1);
    width: 100%;
    margin-left: 0;
  }

  .main-nav-container {
    margin-top: 2%;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    width: 80%;
    margin-left: 0;
    text-align: center;
  }

  .main-nav-container.animate {
    opacity: 1;
    transform: translateY(0);
  }

  .gt-button-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .get-started-btn, .learn-more-btn {
    font-size: 1rem;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s ease, transform 0.2s ease;
  }

  .get-started-btn {
    background-color: #2a9d8f;
    color: #fff;
  }

  .learn-more-btn {
    background-color: #e76f51;
    color: #fff;
  }

  .mobile-home-gif {
    display: inline;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 20px;
    margin-right: 0;
    z-index: -1;
    order: 2;
    width: 75%;
  }

  .video-container {
    display: none;
}
  .video{
    display: none;
  }
}

@media (max-width: 480px) {
  .main-image-section {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: "Ubuntu", serif;
    margin-top: 15%;
    z-index: 1;
  }

  .overlay-text {
    text-align: center;
  }

  .overlay-text h1 {
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;
  }

  .overlay-text p {
    font-size: 1rem;
    font-weight: normal;
    width: 100%;
  }

  .overlay-text h1 .highlight {
    display: inline-block;
    background: linear-gradient(to right, hsl(0, 9%, 9%) 0, hsl(0, 9%, 98%) 10%, hsl(0, 0%, 2%) 20%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shine 2s infinite linear;
  }

  .center p.highlight1 {
    color: #050505;
    font-weight: bold;
  }

  .highlight2 {
    color: #e76f51;
    font-weight: bold;
  }

  .text p {
    font-size: 1rem;
    line-height: 1.4;
    color: rgb(1, 1, 1);
    margin-left: 0;
  }

  .main-nav-container {
    margin-top: 2%;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .main-nav-container.animate {
    opacity: 1;
    transform: translateY(0);
  }

  .gt-button-container {
    display: flex;
    flex-direction: row; /* Stack buttons vertically */
    gap: 1.5rem; /* Adjust the gap for smaller screens */
    justify-content: center;
    align-items: center;
  }

  .get-started-btn, .learn-more-btn {
    font-size: 1rem;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s ease, transform 0.2s ease;
  }

  .get-started-btn {
    background-color: #2a9d8f;
    color: #fff;
  }

  .learn-more-btn {
    background-color: #e76f51;
    color: #fff;
  }

  .mobile-home-gif {
    flex-direction: column-reverse;
    margin-top: 0;
    margin-right: 0;
    justify-content: center;
    z-index: -1;
    order: 2;
    margin-bottom: 10%;
    display: inline;
  }

  .video {
    display: none;
    width: 100%;
    height: auto;
  }

  .video-container {
    display: none;
  }
}

@media (max-width: 1024px) {
  .main-image-section {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-family: "Ubuntu", serif;
    flex-wrap: wrap;
    padding: 20px;
    margin-top: 10%;
    z-index: 1;
  }

  .overlay-text {
    margin-left: 4%;
    text-align: center;
  }

  .overlay-text h1 {
    width: 100%;
  }

  .overlay-text p {
    width: 80%;
    font-weight: bold;
  }

  .overlay-text h1 .highlight {
    display: inline-block;
    background: linear-gradient(to right, hsl(0, 9%, 9%) 0, hsl(0, 9%, 98%) 10%, hsl(0, 0%, 2%) 20%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shine 2s infinite linear;
  }

  .center p.highlight1 {
    color: #050505;
    font-weight: bold;
  }

  .highlight2 {
    color: #e76f51;
    font-weight: bold;
  }

  .text p {
    width: 100%;
    margin-left: 0;
  }

  .main-nav-container {
    margin-top: 2%;
    opacity: 0;
    transform: translateY(10%);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    width: 90%;
    margin-left: 0;
    text-align: center;
  }

  .main-nav-container.animate {
    opacity: 1;
    transform: translateY(0);
  }

  .gt-button-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }

  .get-started-btn, .learn-more-btn {
    cursor: pointer;
    transition: background-color 0.5s ease, transform 0.2s ease;
  }

  .get-started-btn {
    background-color: #2a9d8f;
    color: #fff;
  }

  .learn-more-btn {
    background-color: #e76f51;
    color: #fff;
  }

  .mobile-home-gif {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    margin-top: 30px;
    margin-right: 0;
    z-index: -1;
    order: 2;
    width: 60%;
  }

  .video-container {
    display: none;
  }
}

