.header {
  position: absolute;
  width: 80%;
  height: 70px;
  top: 2%;
  left: 8%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Ubuntu", serif;
  background-color: transparent;
  padding: 0 1rem;
  box-sizing: border-box;
}

.logo-header img {
  height: 50px;
  object-fit: contain;
  cursor: pointer;
}

.header-nav ul {
  list-style-type: none;
  display: flex;
  gap: 5rem;
  margin: 0;
  padding: 0;
}

.header-nav li {
  display: inline; 
}

.header-nav-btn {
  text-decoration: none; 
  font-size: 1rem; 
  font-weight: bold;
  color: #333; 
  transition: color 0.3s ease, transform 0.2s ease; 
}

.header-nav-btn:hover {
  color: #e76f51; 
  transform: scale(1.1); 
  text-decoration: none;
}



@media (max-width: 768px) {
  .header {
    width: 90%; /* Adjust header width for mobile */
    top: 2%;
  }

  .header-nav ul {
    flex-direction: row; /* Stack nav items vertically */
    gap: 4rem; /* Smaller gap for mobile */
    margin-left: 0;
    text-align: center; /* Center align text */
  }

  .header-nav-btn {
    font-size: 10px; /* Adjust font size for mobile */
  }

  .logo-header img {
    height: 15px;
  }
}
