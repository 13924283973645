.privacy-policy-page {
  max-width: 1420px;
  margin: 0 auto;
  font-family: "Ubuntu", serif;
  background-color: #f9fafc;
  color: #333;
  margin-top: 8%;
}

.policy-header {
  text-align: center;
  padding: 40px 20px;
  color: #333;
  border-radius: 8px;
}

.policy-logo {
  max-width: 80px;
  margin-bottom: 20px;
}

.policy-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

.policy-content {
  margin-top: 30px;
}

.policy-content section {
  margin-bottom: 30px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.policy-content h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a90e2;
  margin-bottom: 10px;
}

.policy-content p,
.policy-content ul {
  font-size: 1rem;
  line-height: 1.6;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

a {
  color: #4a90e2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.policy-footer {
  margin-top: 40px;
  padding-top: 15px;
  border-top: 1px solid #ccc;
  color: #333;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 40px;
}

.policy-footer p {
  margin-bottom: 50px;
}

.logo-p img {
  height: 50px;
  margin-top: 20px;
}