.dashboard-section {
  display: flex;
  flex-direction: column;
  background-color: #f4faff;
  font-family: "Ubuntu", serif;
  padding: 80px;
}

.header-text {
  margin-bottom: 60px;
  position: relative;
  text-align: center;
}

.header-text h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.header-text h1 .highlight {
  display: inline-block;
  background: linear-gradient(to right, rgba(110, 108, 109, 0.700), rgba(110, 108, 109, 0.700), hsl(0, 0%, 1%) 20%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: shine 2s infinite linear;
  -webkit-animation: shine 2s infinite linear;
}

.header-text p {
  font-size: 22px;
  line-height: 1.7;
  max-width: 900px;
  margin: auto;
  color: #444;
  background: black;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.dashboard-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: start;
  max-width: 1400px;
  margin: auto;
}

.dashboard-image {
  width: 100%;
  height: 90%;
  border-radius: 15px;
}

.db-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 10px;
}

.feature-box {
  padding: 20px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: rotate 5s infinite ease-in-out;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: rotate 5s infinite ease-in-out;
}

.feature-box:hover {
  transform: translateY(-5px) rotate(-2deg);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

/* Adding unique background colors to each feature box */
.feature-box:nth-child(1) {
  background: linear-gradient(135deg, rgba(243, 148, 204, 0.2), rgba(255, 150, 200, 0.3));
}

.feature-box:nth-child(2) {
  background: linear-gradient(135deg, rgba(163, 226, 244, 0.2), rgba(150, 250, 255, 0.3));
}

.feature-box:nth-child(3) {
  background: linear-gradient(135deg, rgba(200, 247, 212, 0.2), rgba(72, 228, 148, 0.3));
}

.feature-box:nth-child(4) {
  background: linear-gradient(135deg, rgba(244, 223, 167, 0.2), rgba(255, 250, 150, 0.3));
}

.feature-box h3 {
  color: #1c1f37;
  font-size: 20px;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.6), -1px -1px 2px rgba(0, 0, 0, 0.2);
}

.feature-box p {
  color: #333;
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  text-shadow: 0.5px 0.5px 1px rgba(255, 255, 255, 0.5), -0.5px -0.5px 1px rgba(0, 0, 0, 0.1);
}


@media (max-width: 768px) {
  .dashboard-section {
    padding: 40px;
  }

  .header-text h1 {
    font-size: 2rem;
    text-align: center;
  }

  .header-text p {
    font-size: 18px;
    max-width: 100%;
    margin: 20px auto;
  }

  .dashboard-content {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .dashboard-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .db-features {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 10px;
  }

  .feature-box {
    padding: 15px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: none;
  }

  .feature-box h3 {
    font-size: 18px;
    text-align: center;
  }

  .feature-box p {
    font-size: 16px;
    text-align: center;
  }
}


@media (max-width: 480px) {
  .header-text h1 {
    font-size: 28px;
  }

  .header-text p {
    font-size: 14px;
  }

  .feature-box {
    padding: 8px;
    font-size: 12px;
    text-align: center;
  }

  .feature-box h3 {
    font-size: 14px;
  }

  .feature-box p {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .dashboard-section {
    padding: 40px;
  }
}