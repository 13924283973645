.login-container {
  width: 400px;
  margin: 10% auto;
  margin-top: 13%;
  padding: 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  font-family: "Ubuntu", serif;
}

.login-logo {
  display: block;
  margin: 0 auto 20px auto;
  height: auto;
  width: 50%;
}

.login-container h2 {
  text-align: center;
  color: #333;
}

.login-container p {
  margin-top: 20px;
  text-align: center;
  color: #555;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, button {
  width: 70%;
  margin-left: 30px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

button {
  color: white;
  border: none;
  cursor: pointer;
  justify-content: center;
}

.log-btn{
  background-color: #eab167;
  width: 30%;
  margin-left: 35%;
  margin-top: 15px;
}

button:hover {
  background-color: #083215;
}

p{
  font-size:16px;
}

.nav-link{
  margin-top: 30px;
}

@media (max-width: 768px) { 
  .login-container {
    width: 90%; 
    margin: 5% auto; 
    padding: 20px; 
  }
  p{
    font-size:10px;
  }
  .log-btn{
    background-color: #eab167;
    width: 30%;
    margin-left: 35%;
    margin-top: 15px;
  }
}

@media (max-width: 480px) { 
  .login-container {
    width: 60%; 
    margin-top: 40%;
    padding: 30px; 
  }

  .login-logo {
    width: 50%; 
  }

  .login-container h2 {
    font-size: 1rem; 
  }

  .log-btn {
    width: 30%; 
  }
}