main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.bot-logo {
  display: block;
  margin: 20px auto;
  height: auto;
  width: 5%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 120px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
}

.chat-history {
  overflow-y: auto;
  max-height: 70vh;
  padding: 30px;
  border-radius: 8px;
  margin: 60px 0;
}

.chat-message-container {
  display: flex;
  flex-direction: column;
}

.chat-message {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.user-message {
  justify-content: flex-end;
}

.bot-message {
  justify-content: flex-start;
}

.message-bubble {
  padding: 10px 15px;
  max-width: 60%;
  border-radius: 15px;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
}

.user-message .message-bubble {
  background-color: #05930a;
  color: white;
  border-top-right-radius: 0;
  align-self: flex-end;
}

.bot-message .message-bubble {
  background-color: #f0f0f0;
  color: #000;
  border-top-left-radius: 0;
  align-self: flex-start;
}

.loading-icon {
  text-align: center;
  margin-bottom: 20px;
  position: fixed; /* Keep loading icon fixed */
  bottom: 100px; /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50px;
  width: 60%; /* Adjust width as needed */
  height: 50px;
  padding: 0 20px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.search-bar input {
  flex-grow: 1; /* Allow input to take the available space */
  border: none;
  outline: none;
  font-size: 18px;
  margin-top: 10px;
  padding: 10px 15px;
  width: 100%;
  margin: 0;
}

.search-bar button.send-icon {
  border: none;
  cursor: pointer;
  color: #4f8051;
  background-color: transparent;
  width: 40px;
  margin-top: 12px;
  margin-right: 20px;
  margin-left: 10px;
}

.username-display{
  position: fixed;
  margin-top: 60%;
}
