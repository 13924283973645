.reg-container {
  width: 400px;
  margin: 10% auto;
  padding: 80px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}

.reg-logo {
  display: block;
  margin: 0 auto 20px auto;
  height: auto;
  width: 50%;
}

.reg-container h2 {
  text-align: center;
  color: #333;
}

.reg-container p{
  margin-top: 20px;
  text-align: center;
  color: #555;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, button {
  width: 70%;
  margin-left: 30px;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

button {
  color: white;
  border: none;
  cursor: pointer;
  justify-content: center;
}

input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.reg-btn {
  background-color: #eab167;
  width: 30%;
  margin-left: 35%;
  margin-top: 15px;
}

.reg-btn:hover {
  background-color: #083215;
}

.message {
  margin-top: 15px; 
  font-size: 14px;
  color: #ff0000;
  text-align: center;
}
