.experience-section {
  text-align: center;
  background-color: #f0faff;
  font-family: "Ubuntu", serif;
  padding: 80px;
}

.experience-section h1 {
  font-size: 2.3rem;
  color: #001d42;
  margin-bottom: 40px;
}

.experience-section p {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 40px;
  line-height: 1.7;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.card {
  margin-top: 3%;
  width: 400px;
  height: 350px;
  border-radius: 30px;
  background: #fbfbfb;
  box-shadow: 15px 15px 30px #bebebe;   
  transition: all 0.4s;
  font-size: 30px;
  font-weight: 900;
  position: relative;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.05);
  border-radius: 30px;
  background: #fbfbfb;
  box-shadow: 15px 15px 30px #bebebe;
}

.first-content {
  height: 100%;
  width: 80%;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border-radius: 15px;
  position: absolute;
  backface-visibility: hidden;
  text-align: center;
  left: 10%;
}

.first-content h2{
  font-size: 3rem;
  color: #001d42;
}

.first-content h3{
  font-size: 1.5rem;
  color: rgb(9, 9, 9);
  margin-top: -30px;
}

.first-content p{
  font-size: 1rem;
  color: rgb(7, 7, 7);
}

.card:hover .first-content {
  height: 0px;
  opacity: 0;
}

.second-content {
  height: 0%;
  width: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: all 0.4s;
  font-size: 0px;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  position: absolute;
}

.second-content p {
  color: #012656;
}

.card:hover .second-content {
  opacity: 1;
  height: 100%;
  font-size: 1rem;
  transform: rotateY(0deg);
}

.card .card-icon {
  font-size: 3rem;
  margin-bottom: 15px;
}

.card:nth-child(1) .card-icon {
  color: #f0e3e8;
}

.card:nth-child(2) .card-icon {
  color: #007bff; 
}

.card:nth-child(3) .card-icon {
  color: #28a745;
}

.card-content h3 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.open-ai-btn {
  background-color: #001d42;
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.open-ai-btn:hover {
  background-color: #5c9f54;
}

.logo {
  margin-top: 40px;
}

.aylix-logo {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out;
}

.aylix-logo:hover {
  transform: scale(1.05);
}

.aylix-footer {
  margin-top: 40px;
  padding-top: 15px;
  border-top: 1px solid #ccc;
  color: #333;
  font-size: 0.9rem;
  text-align: center;
}

.aylix-footer p {
  margin: 10px 0;
}

.aylix-footer .footer-links a {
  color: #007bff;
  margin: 0 15px;
  text-decoration: none;
}

.aylix-footer .footer-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .experience-section {
    padding: 40px;
  }

  .experience-section h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .experience-section p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  .cards-container {
    flex-direction: column;
  }

  .card {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 20px;
  }

  .logo {
    margin-top: 40px;
  }

  .aylix-logo {
    max-width: 200px; /* Adjust logo size */
  }

  .aylix-footer p {
    font-size: 0.8rem;
  }

  .aylix-footer .footer-links a {
    font-size: 0.8rem;
    margin: 0 10px;
  }
}

